// React
import React, { FC } from "react";

// Next
import { usePathname } from "next/navigation";
import { useLocale, useTranslations } from "next-intl";

// Components
import { Button } from "@/components/ui/button";
import ModeToggle from "@/components/ui/ModeToggle";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

// Utils
import { getFQCN } from "@/lib/helpers/fqcn/methods";
import { IFQCN_BUI } from "@/utils/types/common";
import { HiLogout } from "react-icons/hi";
import logout from "@/lib/Logout";

type Props = {
  fqcn_bui: IFQCN_BUI;
};

const Navbar: FC<Props> = ({ fqcn_bui }) => {
  const t = useTranslations("Navbar");
  const pathname = usePathname();
  const locale = useLocale();

  const handleLogout = () => {
    logout(locale);
  };

  return (
    <article className="w-full p-3 flex justify-end items-end print:hidden">
      <div className="flex gap-4">
        {pathname.includes("product") && (
          <div>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    id={getFQCN(fqcn_bui, "logout-button")}
                    name="logout-button"
                    className="bg-phpc-blue dark:bg-phpc-blue-dark hover:bg-phpc-blue-dark dark:hover:bg-phpc-blue"
                    onClick={handleLogout}
                  >
                    <HiLogout className="w-5 h-5 text-white print:text-black" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>{t("Logout")}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        )}

        <ModeToggle
          fqcn_bui={{
            Bundle: "navbarBundle",
            Unit: "navBar",
            Interface: "modeToggle",
          }}
        />
      </div>
    </article>
  );
};

export default Navbar;
